import React, { useEffect, useState, useRef } from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import { createCustomEqual } from "fast-equals"
import { isLatLngLiteral } from "@googlemaps/typescript-guards"
import { mapStyle } from "./map-options"
import style from "./map.mod.scss"

// https://developers.google.com/maps/documentation/javascript/react-map#map-component-props
// because React does not do deep comparisons, a custom hook is used
// see discussion in https://github.com/googlemaps/js-samples/issues/946
const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
  }
  return deepEqual(a, b);
});
function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}
function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}


const Marker = (options) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions({...options, icon: {
          path: 'M14.67 0C6.97 0 0 6.24 0 13.94 0 25.4 11.7 26.6 14.67 44.02c2.98-17.42 14.67-18.63 14.67-30.08C29.34 6.24 22.37 0 14.67 0zm0 20.17a5.5 5.5 0 1 1 5.5-5.5 5.5 5.5 0 0 1-5.5 5.5z',
          scale: 1,
          strokeOpacity: 0,
          fillColor: '#637e4b',
          fillOpacity: 1,
          anchor: new window.google.maps.Point(15, 44),
      }});
    }
  }, [marker, options]);
  return null;
};


const MapComponent = ({onClick, onIdle, markers, ...options}) => {
  const ref = useRef()
  const [map, setMap] = React.useState()

  useEffect(() => {
    if (ref.current && !map) {
      let m = new window.google.maps.Map(ref.current, {
        center: markers ? {
          lat: markers[0].lat,
          lng: markers[0].lng
        } : {},
        mapTypeControl: false,
        streetViewControl: false,
        zoom: 12,
        zoomControl: true,
        draggable: true,
        styles: mapStyle
      })
      // set bounds
      let bounds = new window.google.maps.LatLngBounds();
      for (let i=0; i<markers.length; i++) {
        bounds.extend(new window.google.maps.LatLng({
          lat: markers[i].lat,
          lng: markers[i].lng,
        }))
      }
      let padding = 0
      let w = window.innerWidth
      if (w >= 767) {
        let frameW = w > 1920 ? 1920 : w
        padding = {left: ((frameW / 100) * 10) + 400}
      }
      if (markers.length === 1) {
        bounds.extend(new window.google.maps.LatLng({
          lat: markers[0].lat + 0.01,
          lng: markers[0].lng,
        }))
        bounds.extend(new window.google.maps.LatLng({
          lat: markers[0].lat - 0.01,
          lng: markers[0].lng,
        }))
      }
      m.fitBounds(bounds, padding);
      if (m.getZoom() > 12) {
        m.setZoom(12)
      }
      setMap(m)
    }
  }, [ref, map])
  
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])
  
  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <div ref={ref} className={style.map}>
      {markers && markers.map((marker,i) => {
        return (
          <Marker position={{lat: marker.lat, lng: marker.lng}} map={map} key={`marker_${marker.lat}_${marker.lng}`} />
        );
      })}
    </div>
  )
}

const Map = ({ markers }) => {
  let mapKey = "AIzaSyBjoYQ15gTVhfZ9QEvxdkcXkJ4AwBW8ir4"
  //AIzaSyCsQraAcoiv7pOfKrqT8Mpa-6jL0dhxL_Y
  return (
    <Wrapper apiKey={mapKey} libraries={["places"]}>
      <MapComponent markers={markers} />
    </Wrapper>
  )
}

export default Map
