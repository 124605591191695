import React from "react"
import style from "./winery-hero.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import Container from "../container/container"
import Pin from "../icons/pin"
import demoImage from "../../assets/images/stock-winery.jpeg"

const WineryHero = ({ heading, address, image }) => {
  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  return (
    <Container>
      <BorderWrapper topColor="light" bottomColor="white">
        <div className={style.hero}>
          <div className={style.hero__imageWrapper}>
            <img src={image} alt="" onError={handleImgError} />
          </div>
          <div className={style.hero__content}>
            <Container
              addBorderPadding
              gutters
              pt={3}
              pb={3}
              textAlign="center"
              width="large"
            >
              <Pin />
              <p>{address}</p>
              <h1>{heading}</h1>
            </Container>
          </div>
        </div>
      </BorderWrapper>
    </Container>
  )
}

export default WineryHero
