import React from "react"
import Map from "./map"
import style from "./map.mod.scss"
import Container from "../container/container"

const MapFrame = ({children, markers}) => {
  return (
    <div className={style.mapFrame}>
      <Container gutters width={"medium"} className={style.mapContainer}>
        <div className={style.mapPanel}>
          {children}
        </div>
      </Container>
      <Map markers={markers} />
    </div>
  )
}

export default MapFrame
