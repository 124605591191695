import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import style from "../components/winery/winery.mod.scss"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import WineryHero from "../components/winery/winery-hero"
import ProductSlider from "../components/product/product-slider"
import MapFrame from "../components/map/map-frame"
import FilterGrid from "../components/filter/filter-grid"
import Button from "../components/button/button"
import {setWineEvent} from '../services/gtm-events'

const WineryDetail = ({ data: { wines, portalWinery } }) => {
  const { name, banner, logo, latitude, longitude } = portalWinery

  const pagesize = 12
  const [loadMore, setLoadMore] = useState(false)
  const filteredWines = wines.nodes
  const [list, setList] = useState([...wines.nodes.slice(0, pagesize)])
  const [hasMore, setHasMore] = useState(wines.nodes.length > pagesize)

  useEffect(() => {
    if (loadMore && hasMore) {
      let currentLength = list.length
      let isMore = currentLength < filteredWines.length
      let nextResults = isMore
        ? filteredWines.slice(currentLength, currentLength + pagesize)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
      setHasMore(currentLength + nextResults.length < filteredWines.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore])

  useEffect(() => {
    const eventData = {
      winery: name.toLowerCase().replace(' ', '-')
    }
    setWineEvent(eventData)
  }, [])

  return (
    <Layout
      seoTitle={`iHeartWine | Explore Wineries | ${name}`}
      seoDescription={portalWinery.description}
    >
      <div>
        <WineryHero
          heading={name}
          address={`${portalWinery.address} ${portalWinery.suburb} ${portalWinery.state} ${portalWinery.postcode} ${portalWinery.country}`}
          image={banner}
        />
        <Container pt={1} pb={2}>
          <img src={logo} alt={`${name} logo`} className={style.logo} />
        </Container>
        <Container gutters pb={4} width={"medium"}>
          <div className={style.description}>{portalWinery.description}</div>
          {portalWinery.minimum_wines_per_order > 0 && (
            <div className={style.minorder}>
              {name} has a minimum order quantity of <b>{portalWinery.minimum_wines_per_order}</b> bottles.
              <br />You can order any of their range to meet minimum order requirements.
            </div>
          )}
        </Container>
        <div>
          {filteredWines.length > pagesize && (
            <div>
              <Container gutters pb={1} width={"medium"}>
                <h2 className={style.gridHeader}>
                  Wines <sup>({filteredWines.length})</sup>
                </h2>
              </Container>

              <Container gutters width="large">
                <FilterGrid
                  products={list}
                  isTouchScreen={false}
                  isCellar={false}
                />
                {hasMore && (
                  <Container
                    width="large"
                    gutters
                    pt={0}
                    pb={3}
                    textAlign="center"
                  >
                    <Button
                      onClick={() => {
                        setLoadMore(true)
                      }}
                    >
                      Load more
                    </Button>
                  </Container>
                )}
              </Container>
            </div>
          )}
        </div>
        <div>
          {filteredWines.length <= pagesize && (
            <Container pt={2} pb={2} bg={"light"} width={"none"}>
              <ProductSlider
                heading={`${name} wines`}
                subHeading={"Discover"}
                items={wines.nodes}
              />
            </Container>
          )}
        </div>

        <Container bg={"light"} pb={2}>
          <MapFrame
            markers={[
              { lat: parseFloat(latitude), lng: parseFloat(longitude) },
            ]}
          >
            <p className={style.subHeading}>Address</p>
            <p className={style.heading}>{name}</p>
            <p>
              {portalWinery.address}
              <br />
              {portalWinery.suburb}, {portalWinery.postcode}
              <br />
              {portalWinery.state}
              <br />
              {portalWinery.country}
            </p>
          </MapFrame>
        </Container>
      </div>
    </Layout>
  )
}

export default WineryDetail

export const query = graphql`
  query WineryDetailQuery($id: String!, $wineryId: Int!) {
    portalWinery(id: { eq: $id }) {
      name
      id
      wineryId
      banner
      address
      state
      suburb
      postcode
      latitude
      longitude
      country
      logo
      description
      minimum_wines_per_order
      postcode
      region_id
      region_name
      region {
        country
        google_map_url
        id
        name
        state
      }
    }
    wines: allPortalWine(filter: { winery: { id: { eq: $wineryId } } }) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
        }
      }
    }
  }
`
