import React from "react"
import ProductCard from "../product/product-card"
import style from "./filter-grid.mod.scss"
import { AnimatePresence, motion } from "framer-motion"

const FilterGrid = ({ products, isTouchScreen, isCellar, stickerText }) => {
  return (
    <motion.div layout className={style.grid}>
      <AnimatePresence mode={"popLayout"}>
        {products.map((product) => {
          // console.log(product)
          return (
            <ProductCard
              key={product.id}
              product={product}
              isTouchScreen={isTouchScreen}
              isCellar={isCellar}
              stickerText={stickerText}
            />
          )
        })}
      </AnimatePresence>
    </motion.div>
  )
}

export default FilterGrid
